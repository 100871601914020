

































































































































































































































































































































































.topCss {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  flex-direction: row;
  .rzmb_1 {
    width: 160px;
    height: 190px;
    margin-right: 10px;
    p {
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rzmb_2 {
    width: 600px;
    height: 150px;
    margin-right: 60px;
    p {
      line-height: 24px;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .rzmb_3 {
    padding-top: 155px;
    height: 150px;
  }
}
.demo-image__preview {
  width: 120px;
  height: 120px;
  float: left;
  margin-right: 8px;
  margin-bottom: 6px;
}
.noticeContent {
  .el-textarea /deep/.el-textarea__inner {
  min-height: 100px !important;
  resize: none !important;
}
}

