.topCss {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  flex-direction: row;
}
.topCss .rzmb_1 {
  width: 160px;
  height: 190px;
  margin-right: 10px;
}
.topCss .rzmb_1 p {
  text-align: center;
}
.topCss .rzmb_1 img {
  width: 100%;
  height: 100%;
}
.topCss .rzmb_2 {
  width: 600px;
  height: 150px;
  margin-right: 60px;
}
.topCss .rzmb_2 p {
  line-height: 24px;
}
.topCss .rzmb_2 p:first-child {
  margin-bottom: 20px;
}
.topCss .rzmb_3 {
  padding-top: 155px;
  height: 150px;
}
.demo-image__preview {
  width: 120px;
  height: 120px;
  float: left;
  margin-right: 8px;
  margin-bottom: 6px;
}
.noticeContent .el-textarea /deep/ .el-textarea__inner {
  min-height: 100px !important;
  resize: none !important;
}
